/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');

import 'font-awesome/css/font-awesome.css';
import 'font-roboto/dist/styles/roboto.min.css';

import 'cropper/dist/cropper.min.css';

import '../css/global.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
global.$ = global.jQuery = $;

//const document.querySelector = require('jquery');

//import Cropper from 'cropper/dist/cropper';
//import 'cropperjs/dist/cropper.min.css';

//import Cropper from 'cropperjs/dist/cropper.esm';

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');


require('popper.js');

require('jquery-mask-plugin/src/jquery.mask.js');

require('cropper/dist/cropper.min.js');
//require('jquery-cropper/dist/jquery-cropper.min.js');


//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');


// Comandos específicos para ativação dos módulos do Bootstrap.
$(document).ready(function(){
    //$('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip()
});

// Telefone MASK.
var TelefoneMaskBehavior = function(val)
{
    return val.replace(/\D/g, '').length === 11 ? '(00) 0 0000-0000' : '(00) 0000-00009';
},
spTelefoneOptions = {
    onKeyPress: function(val, e, field, options)
    {
        field.mask(TelefoneMaskBehavior.apply({}, arguments), options);
    }
};

// Estrelas MASK.
var EstrelasMaskBehavior = function(val)
{
    return '0.0';
},
spEstrelasOptions = {
    onKeyPress: function(val, e, field, options)
    {
        field.mask(EstrelasMaskBehavior.apply({}, arguments), options);
    }
};

// CPF/CNPJ MASK.
var CpfCnpjMaskBehavior = function(val)
{
    return val.replace(/\D/g, '').length <= 11 ? '000.000.000-009' : '00.000.000/0000-00';
},
cpfCnpjpOptions = {
    onKeyPress: function(val, e, field, options)
    {
        field.mask(CpfCnpjMaskBehavior.apply({}, arguments), options);
    }
};

$(document).ready(function()
{
    $('.telefones').mask(TelefoneMaskBehavior,  spTelefoneOptions);
    $('.cpf_cnpj').mask(CpfCnpjMaskBehavior,    cpfCnpjpOptions);
    $('.estrelas').mask(EstrelasMaskBehavior,   spEstrelasOptions);
});


$(document).ready(function()
{
    $("#submit-form-user").on('click', function(event)
    {
        $("#form-user").submit();
    });
    $("#submit-form-user-p").on('click', function(event)
    {
        $("#form-user").submit();
    });

    $("#submit-form-tipo").on('click', function(event)
    {
        $("#form-tipo").submit();
    });
    $("#submit-form-tipo-p").on('click', function(event)
    {
        $("#form-tipo").submit();
    });

    $("#submit-form-categoria").on('click', function(event)
    {
        $("#form-categoria").submit();
    });
    $("#submit-form-categoria-p").on('click', function(event)
    {
        $("#form-categoria").submit();
    });

    $("#submit-form-plano").on('click', function(event)
    {
        $("#form-plano").submit();
    });
    $("#submit-form-plano-p").on('click', function(event)
    {
        $("#form-plano").submit();
    });

    $("#submit-form-produto").on('click', function(event)
    {
        $("#form-produto").submit();
    });
    $("#submit-form-produto-p").on('click', function(event)
    {
        $("#form-produto").submit();
    });
});






$(document).ready(function()
{
    /**
     * Variáveis para utilização futura.
     */
    var $image                      = $(".bootstrap-modal-cropper > img"),
        originalData                = {},
        croppedImage                = {},
        $aspectRatio                = "1",
        $idimage                    = "",
        $idimagestorage             = "",
        $data_width                 = "130",
        $data_height                = "130",
        $canvas                     = document.createElement('canvas')
    ;

    /**
     * Trata click em link para abertura de modal com configurações personalizadas.
     */
    /*
    $(document).on("click", ".open-modal-image-dialog", function()
    {
        // Configurações da Imagem.
        $idimage                    = $(this).data('idimage');
        $idimagestorage             = $(this).data('idimagestorage');
        // Configurações de apresentação / salvamento.
        $aspectRatio                = $(this).data('aspectratio');
        $data_width                 = $(this).data('width');
        $data_height                = $(this).data('height');
        // Carrega Modal.
        $("#bootstrap-modal-image").modal('show');
    });
    */

    /**
     * Monta Cropper Modal.
     */
    $("#bootstrap-modal-image").on("shown.bs.modal", function()
    {
        $image.cropper(
        {
            data: originalData,
            aspectRatio: $aspectRatio,
            preview: "#img-previa",
            ready: function(data){}
        });

        /**
         * Salva imagem Avatar.
         */
        $("#user-avatar-btn-salvar").click( function()
        {
            // Obtém imagem original.
            originalData = $image.cropper("getData");

            // Monta imagem original.
            croppedImage = $image.cropper('getCroppedCanvas');//.toDataURL(); // base64 image
            // Monta imagem cortada em tamanho específico.
            croppedImage = imageToDataUri(croppedImage, $canvas, $data_width, $data_height); // Redimensiona a imagem.

            // Atualiza BASE64 no form.
            $("#" + $idimage).attr('src', croppedImage);

            // Adiciona texto BASE64 para inclusão em Banco De Dados.
            if( $idimagestorage != "" )
            {
                $("#" + $idimagestorage).val(croppedImage);
            }

            // Fecha modal.
            $("#bootstrap-modal-image").modal('hide');
        });

        $("#user-avatar-btn-clear").click( function() {
            $("#" + $idimage).attr('src', 'https://via.placeholder.com/130');
            $("#" + $idimagestorage).val('');

            // Fecha modal.
            $("#bootstrap-modal-image").modal('hide');
        });

    });

    // AVATAR : Ao clicar no botão de enviar imagem, clica no input file.
    $('#inputImageAvatar').click(function(){
        $('#user_avatar_file').click();
    });
    // FOTO DE CAPA : Ao clicar no botão de enviar imagem, clica no input file.
    $('#inputImageFotoCapa').click(function(){
        $('#user_foto_capa_file').click();
    });
    // AVATAR PRODUTO: Ao clicar no botão de enviar imagem, clica no input file.
    $('#inputImageAvatarProduto').click(function(){
        $('#produto_avatar_file').click();
    });

    /**
     * Realiza o carregamento de imagem em tempo real.
     */
    var $inputImageAvatar = $(".inputImageModal");
    if (window.FileReader)
    {
        $inputImageAvatar.change(function()
        {
            // Configurações da Imagem.
            $idimage                    = $(this).data('idimage');
            $idimagestorage             = $(this).data('idimagestorage');
            // Configurações de apresentação / salvamento.
            $aspectRatio                = $(this).data('aspectratio');
            $data_width                 = $(this).data('width');
            $data_height                = $(this).data('height');

            // Carrega Modal.
            $("#bootstrap-modal-image").modal('show');

            var fileReader  = new FileReader();
            var files       = this.files;
            var file        = this.files[0];

            if (!files.length){return; }

            if (/^image\/\w+$/.test(file.type))
            {
                fileReader.readAsDataURL(file);
                fileReader.onload = function()
                {
                    $image.cropper("reset", true).cropper("replace", this.result);
                    $(".bootstrap-modal-cropper > .image").val( this.result );
                };
            }
            else
            {
                $("#mensagens").append('<div class="alert alert-danger alert-dismissible" role="alert">\
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>\
                            <strong>Atenção!</strong> Por favor envie uma imagem para continuar.\
                        </div>');
            }
        });
    }

});


/**
 * Faz a conversão da imagem para tamanho correto a ser gravado.
 */
function imageToDataUri(img, canvas, width, height)
{
    // create an off-screen canvas
    var ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width    = width;
    canvas.height   = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
}

/*
##########
# Este código foi movido para dentro do Template para adicionar-mos as restrições de quantidade permitidas a inclusão.
##########

$(document).ready(function()
{
    var $wrapper = $('.js-video-wrapper');
    $wrapper.on('click', '.js-remove-video', function(e) {
        e.preventDefault();
        $(this).closest('.js-video-item')
            .fadeOut()
            .remove();
    });
    $wrapper.on('click', '.js-video-add', function(e) {
        e.preventDefault();
        // Get the data-prototype explained earlier
        var prototype = $wrapper.data('prototype');
        // get the new index
        var index = $wrapper.data('index');
        // Replace '__name__' in the prototype's HTML to
        // instead be a number based on how many items we have
        var newForm = prototype.replace(/__name__/g, index);
        // increase the index with one for the next item
        $wrapper.data('index', index + 1);
        // Display the form in the page before the "new" link
        $(this).before(newForm);
    });
});
*/